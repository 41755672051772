@import "src/global/global.scss";
$transition: 300ms ease-in-out;

.product_keyword_search_list {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  .product_keyword_search_image_list{
    background-color: #EEEEEE;
    padding: 0;
    display: block;
    transition: 300ms ease-in-out box-shadow;
    cursor: pointer;
    border-radius: 5%;

    &__thumb{
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      border-radius: 5%;
      width: 130px;
      height: 100%;

      &__image {
        overflow: hidden;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5%;
      }

      &__status-top, &__status-bottom, &__wish {
        position: absolute;
        z-index: 1;
      }

      &__status-top, &__status-bottom {
        left: 0.1rem;
        color: #fff;
        font-size: 0.75rem;
        line-height: 1rem;
        padding: 0 0.125rem 0 0.3125rem;

        &:after {
          content: '';
          position: absolute;
          left: 100%;
          top: 0;
        }
      }

      &__status-top {
        top: 0.2rem;
        left: 0.1rem;
        background-color: $brand-color;
        text-transform: uppercase;

        &:after {
          border-left: 0.375rem solid $brand-color;
          border-right: 0px solid transparent;
          border-bottom: 0.5rem solid transparent;
          border-top: 0.5rem solid transparent;
        }
      }

      &__exist {
        top: 0.1rem;
        right: 0.15rem;

        button {
          @extend %button;
          background-color: #FF3D00;
          width: 1.5625rem;
          height: 1.5625rem;
          line-height: 1.5625rem;
          font-size: 0.875rem;
          text-align: center;
          border-radius: 50%;
          color: #fff;

          &.active, &:hover {
            background-color: #FF3D00;
            color: #fff;
          }
        }
      }

      &__wish {
        top: 0.1rem;
        right: 0.15rem;

        button {
          @extend %button;
          background-color: #fff;
          width: 1.6625rem;
          height: 1.6625rem;
          line-height: 1.7625rem;
          font-size: 0.875rem;
          text-align: center;
          border-radius: 50%;
          color: $txt-color;

          &.active, &:hover {
            background-color: #FF3D00;
            color: #fff;
          }
        }
      }
    }
  }

  .product_keyword_search_price_list{
    margin-top: 2%;
    &__sold{
      font-size: 1em;
      font-weight: bold;
      color: $txt-color;
    }

    &__del{
      font-weight: bold;
      font-size: 0.9em;
      color: #5D6154 !important;
    }

    &__price{
      font-weight: bold;
      color: $brand-color-light;
      font-size: 1.4em;
    }

    &__offer{
      font-weight: bold;
      color: $brand-color;
      font-size: 12px;
    }
  }
}

.product_keyword_search_title_list{
  span {
    font-size: 1.2em;
    color: $txt-color;
    @extend %txt-overlap;
    -webkit-line-clamp: 2!important;
    transition: $transition color;
    cursor: pointer;

    &:hover {
      color: $hover-color;
    }
  }
}

.product_keyword_search_rating_list{
  span{
    display: flex;
    align-items: center;
  }
}
