@import "global/global.scss";
.three_tips{
  &__title{
    color: $brand-color;
    font-size: 1.1em;
  }

  &__sub_title{
    font-size: 1.5em;
    font-weight: bold;
  }
}

.tips_main{
  background-color: #e6f7ff;
  margin-top: 5%;
  border-radius: 8px;
}

.three_tips_options{
  margin-top: 7%;
}

.variation_value{
  text-align: justify;
  padding: 3%;

  &__main_title{
    text-align: center;
    color: $brand-color-light;
    font-size: 1.45em;
    font-weight: bold;

    .adm-divider{
      margin: 8px 0!important;
    }
  }
  &__title{
    font-weight: 700;
    font-size: 1.1em;
  }

  &__number{
    font-weight: bold;
    font-size: 1.3em;
    color: $brand-color;
  }

  &__sub_title{
    font-size: 1em;
  }
}

.three_tips_notice{
  margin-top: 3%;
  margin-bottom: 5%;
  color: $txt-light-color;

  &__order{
    font-weight: bold;
    color: $brand-color;
  }

  &__title_bar{
    color: $bgc-button;
    font-weight: bold;
  }

  &__icons{
    font-size: 18px;
    color: $brand-color;
  }
}

.cascader_view{
  .adm-cascader-view-item-active{
    color: black!important;
  }

  .adm-list-item-content-extra{
    display: none;
  }
}

.invisible{
  display: none;
}
