@import "src/global/global.scss";
@import "src/global/abstracts/_mixins.scss";

.tab-bar-request{
  height: 45px;
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 999;

  .adm-tab-bar{
    min-height: 45px;
  }

  @include min-media-width(767) {
    width: 500px!important;
    margin: 0 auto!important;
    left: 0!important;
    right: 0!important;
  }

  .adm-badge-wrap{
    display: block;
  }

  .adm-tab-bar-item{
    color: white!important;
  }

  .tab_bar_cart{
    flex: 0!important;
    margin-left: 3%;
  }

  .tab_bar_favorite{
    flex: 0!important;
    margin-left: 3%;
  }

  .request_button{
    justify-content: normal!important;
    display: inline-block!important;
    padding: 0!important;
    button{
      border-radius: 0!important;
      border-color: white;
      padding: 13px 22px !important;
      font-size: 14px !important;
      color: white;
      --border-radius: 0!important;
      --border-width: 0!important;
    }
    .adm-tab-bar-item-icon{
      font-size: 22px!important;
      margin: 0!important;
    }
  }

  .tab_bar_button_check{
    justify-content: normal!important;
    display: inline-block!important;
    padding: 0!important;
    button{
      border-radius: 0!important;
      border-color: white;
      padding: 13px 22px !important;
      font-size: 14px !important;
      color: white;
      --border-radius: 0!important;
      --border-width: 0!important;
    }
    .adm-tab-bar-item-icon{
      font-size: 22px!important;
      margin: 0!important;
    }
  }
}

.request_cart{
  background-color: $brand-color;
  color: white;
  font-weight: bold;
}
