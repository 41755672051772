@import "src/global/global.scss";

.read_title{
  color: #7d7d7d;
}

.non_read_title{
  color: black;
}

.notification{
  margin-bottom: 5%;
  align-items: center!important;

  &__date{
    text-align: right;
    color: $brand-color;
  }

  &__title{
    margin-left: 3%;
    font-size: 1em;
    font-weight: bold;
  }

  &__icons{
    border: 1px solid;
    border-radius: 25px;
    padding: 17%;
    font-size: 16px;
    color: var(--adm-color-primary)
  }

  &__grid{
    background-color: #F5F5F5;
    padding: 5% 4%;
    border-radius: 8px;

    &__item{
      align-items: center;
    }
  }

  .notification_grid_item{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__badge{
    --right : 0.5rem;
    --top : 0.5rem;
  }

  &:last-child{
    margin-top: 0!important;
  }



  &__sub_title{
    color: $txt-color;
  }
}

.loadmore_button{
  margin: 0 3%;
  background-color: white;
}
