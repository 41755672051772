@import "global/global.scss";
.profile{
  background-image: linear-gradient(90deg, rgba(246,231,224,1) 0%, rgba(234,229,226,1) 50%, rgba(223,228,227,1) 92%, rgba(233,219,223,0.9780287114845938) 100%);
  padding: 4%;

  &__grid{
    background-image: linear-gradient(90deg, rgba(246,231,224,1) 0%, rgba(234,229,226,1) 50%, rgba(223,228,227,1) 92%, rgba(233,219,223,0.9780287114845938) 100%);
    margin: 1% 0 5% 0;

    &__layout{
      align-items: baseline;
    }
  }

  .setting_icon{
    font-size: 20px;
  }

  .profile_details{
    .adm-space {
      --vertical-size: 2px!important;
    }

    &__name{
      font-weight: bold;
      color: black;
    }

    &__balance{
      font-weight: bold;
      color: $brand-color;
    }

    &__vip{
      font-weight: bold;
      color: #389e0d;
    }
  }

  .profile_details_icons{
    text-align: right!important;
  }

  .profile_list{
    padding: 3% 0 0.3% 0;
    margin-top: 2%;
    text-align: center;

    &__icon{
      font-size: 22px;
    }

    &__text{
      font-size: 12px;
      font-weight: bold;
      &__action{
        font-size: 10px!important;
        font-weight: bold;
      }
    }
  }

  .my-order{
    padding: 1%;
    margin-top: 1%;

    &__orders{
      text-align: left;
      font-size: 1.3em;
    }
    &__view-all{
      text-align: right;
      color: $brand-color;
      font-size: 1.3em;

      a{
        color: $brand-color!important;
        text-decoration: none;
      }
    }
    .order_menu{
      margin-top: 7%;
      text-align: center;
      .adm-space {
        --vertical-size: 2px!important;
      }
      &__icon{
        font-size: 26px;
        color: #ED1B2E;
      }

      &__text{
        font-size: 10px;
        font-weight: bold;
      }
    }

    .return_cancel{
      padding: 1% 6%;
      margin-top: 6%;
      text-align: center;
      font-weight: bold;
      font-size: 13px;
    }
  }

  .order_status{
    padding: 4% 0 1% 0;

    .status_image{
      width: 100%;
      height: auto;
    }

    &__track-package{
      margin-top: 4%;
    }

    .order_menu_status{
      display: flex;
      justify-content: space-between;

      &__status{
        font-weight: bold;
        font-size: 15px;
        color: #0050b3;
      }

      &__date{
        font-weight: bold;
        color: #ad2102;
      }
    }
  }

  .our_services{
    padding: 1%;
    margin-top: 1%;

    .services_menu{
      margin: 6% 0;
      text-align: center;
      .adm-space {
        --vertical-size: 2px!important;
      }
      &__icon{
        font-size: 26px;
        color: #ED1B2E;
        font-weight: bold;
      }

      &__text{
        font-size: 10px;
        font-weight: bold;
      }
    }
  }

  .profile_details_edit{
    //padding: 1%;
    margin-top: 1%;

    .details_menu_edit{
      text-align: center;
      padding: 3% 0;
      .adm-space {
        --vertical-size: 2px!important;
      }
      &__icon{
        font-size: 26px;
        color: #ED1B2E;
      }

      &__text{
        font-size: 0.8em;
        font-weight: bold;
      }
    }
  }
}
