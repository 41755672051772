@import "src/global/global.scss";

.login_register_take {
  margin-bottom: 2%;
}

.login_for {
  height: 150px;
  padding: 6%;
  display: flex;
  justify-content: center;
  flex-flow: column;

  &__title {
    font-size: 18px;
    font-weight: bold;
    padding: 0 1%;
  }

  .social_login {
    margin-top: 8% !important;

    &__button {
      display: flex;
      align-items: center;
      background-color: #E6E6E6;
      color: black;
      font-size: 16px !important;
      --border-radius: 5px !important;
      width: 193px;

      span {
        margin-left: 6%;
      }
    }

  }
}

.divider {
  border-color: #D5D5D5;
  margin: 0 6%;
  color: #747474;
}

.login_form {

  .adm-list {
    --padding-left: 14px !important;
  }

  .adm-input-wrapper {
    --font-size: 16px;
    border: 2px solid #E9E8E7;
    border-radius: 8px;

    .adm-input{
      padding: 2% !important;
    }
  }

  .adm-list-default {
    border-top: none !important;
    border-bottom: none !important;
  }

  .adm-list-item-title {
    margin-bottom: 4%;
  }

  .adm-input-wrapper {
    --font-size: 16px;
  }
}

.forget_passport {
  padding: 1% 4% 4% 1%;
  text-align: right;
  color: $brand-color-light;
  font-weight: bold;
}

.forget_password {
  padding: 5% 3%;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 1.5em;
    color: $brand-color-light;
    font-weight: bold;
    margin-bottom: 2%;
  }

  &__title_sub {
    font-size: 1.1em;
  }
}
