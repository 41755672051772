@import "src/global/global.scss";

.form_name{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.register_form{
  padding: 3% 3% 13% 3%;

  .register_title{
    font-size: 1.6em;
    color: $brand-color-light;

    &__sub{
      font-size: 1.1em;
    }
  }

  .register_fillable_form{
    margin-top: 1%;

    .adm-input{
      --font-size : 16px;
      border: 2px solid #E9E8E7;
      border-radius: 8px;
      .adm-input-element{
        padding: 3% 4% !important;
      }
    }

    .adm-list-item-content{
      border-bottom: none!important;
      border-top: none!important;
    }

    .adm-list-default{
      border: none;
    }

    //.adm-list-item{
    //  padding-left: 2px!important;
    //}

    .adm-form .adm-form-footer{
      padding: 12px 4px;
    }
  }
}
