@import "src/global/global.scss";

.seller_details{
  .seller_full_details{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 3% 0;

    &__info{
      margin-left: 3%;
    }

    &__title{
      font-size: 1.4em;
      font-weight: bold;
      color: white;
    }

    &__sub_title{
      margin-top: 3%;
      font-size: 0.8500em;
      color: white;
    }
  }

  .review_button{
    &__rating{
      color: white;
      &__text{
        font-size: 1.3em;
        font-weight: bold;
      }
    }
  }

  .seller_product_details{
    margin-bottom: 15%;

    .masonry_grid_seller{
      margin: 5px 10px;
      columns: 100px 2!important;
      display: block;
      column-gap: 0.6em;

      .search_results_grid{
        background-color: white;
        border-radius: 8px;
        margin-bottom: 0.6em;
        float: left;

        &:nth-child(2n+1) {
          clear: left;
        }
      }
    }

    .masonry_grid_seller > * {
      break-inside: avoid;
    }


    @supports (grid-template-rows: mesonry) {
      .masonry_grid_seller{
        max-width: 25rem;
        margin: 0 auto;
        grid-template-columns: repeat(2, 1fr) !important;
        grid-template-rows: mesonry;
      }
    }

    .seller_filter{
      padding: 3%;

      &__icons{
        font-size: 18px;
      }
    }

    &__button{
      margin-bottom: 5%;
    }
  }
}

.all_products{
  font-size: 1.3em;
  font-weight: bold;
  color: white;
  align-items: center;
  --gap: 0px!important;
  margin-bottom: -7px;
}

.review_details_grid{
  padding:2% 0;
}
