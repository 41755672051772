@import "src/global/global.scss";

.campaign_image {
  width: 90% !important;
  --width: 100% !important;
  height: 110px !important;
  --height: 110px !important;
  margin-left: 3% !important;

  .adm-image-img {
    width: 100% !important;
    height: auto !important;
  }
}

.swiper_value_tag{
  .adm-swiper-track-inner{
    transform : translate3d(0px, 0px, 0px)!important;
  }
}

.category_campaign {
  margin: 5%;

  &__item {
    place-self: center;
  }

  .category_icons {
    background-color: white;
    border-radius: 25px;

    .adm-image-img {
      padding: 15% !important;
      width: 70%;
      height: 70%;
    }
  }

  .category_title {
    font-size: 1.1em;
    color: white;
    text-align: center;
  }
}

.category_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.campaign_card {
  color: white;
  font-weight: bold;
  font-size: 1.1em;
}

.campaign_product {
  background-color: #EEEEEE;
  padding: 0;
  display: block;
  transition: 300ms ease-in-out box-shadow;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  &__thumb {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}

.campaign_title {
  @extend %txt-overlap;
  font-size: 1.17em;
  color: $txt-color;
}

.campaign_price {
  &__price {
    color: $brand-color-light;
    font-size: 0.9em;
    font-weight: bold;
  }

  &__del {
    font-size: 0.8em!important;
  }
}

.masonry_campaign_grid {
  max-width: 25rem;
  margin: 0 3%;

  .search_campaign_results_grid {
    background-color: white;
    border-radius: 8px;
  }
}

.campaign_title_more {
  &__main {
    color: white;
    font-size: 1.2em;
    font-weight: bold;
  }
}

.campaign_slogan {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.8em;
  border: 1px solid #ffffff;
  border-radius: 8px;

  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  //background-color: #fff;
  background-clip: border-box;

  .card_body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 8px;
    height: 60%;
    border-radius: 0 4px 4px 0;
    background: #ffffff;
    transform: translateY(-50%)
  }
}

.campaigns-header {
  min-height: 100px;
  position: relative;
  z-index: 1;

  .campaigns-header-banner {
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    border-top: 1px solid #ffffff;
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff;

    img {
      width: 100%;
      vertical-align: middle;
      transition: $transition transform;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  .campaigns-header-heading {
    &:before {
      content: "";
      border: none;
    }
    .section-title {
      border-radius: 0 0 8px 8px;
      font-size: 1em;
      display: inline-block;
      text-transform: uppercase;
      padding: 11px 0 10px 0;
      position: relative;
      background-color: $brand-color;
      width: 100%;
      color: white;
      box-shadow: 0 2px 14px rgba(0,0,0,0.11);
      span{
        margin-left: 3%;
      }
    }
  }

  .campaigns-header-timer {
    position: absolute;
    right: 3%;
    bottom: 84%;
    padding-left: 30px;
    border-radius: 0 0 0 50px;

    .countDown {
      display: flex;
      align-items: center;
      flex-direction: row;

      .countDownTimeText {
        margin-top: 10%;

        &__cd_text {
          color: $brand-color;
          font-weight: bold;
          font-size: 14px;
        }
      }

      .countDownTimer {
        display: flex;
        background: #ffffff;
        padding: 1rem;

        &__cd_timer {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.1em 0.1em;

          &__block{
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        &__cd_text {
          color: $brand-color;
          font-weight: bold;
          font-size: 12px;
        }

        &__cd_timer {
          color: #ffffff;

          strong {
            color: $brand-color;
            padding: 2px;
          }
        }

        span {
          margin: 0 1px;
        }

        &__days, &__hours, &__minutes, &__seconds {
          color: white;
          padding: 0.25rem 0.3rem 0.25rem 0.3rem;
          background-color: $brand-color;
          border-radius: 40%;
          border: 2px solid #ffffff;
        }
      }
    }
  }
}

.product_details_image_campaign{
  width: 100% !important;
  //min-height: 390px !important;
  position: relative;
  flex-shrink: 0;
  background-color: #f0f0f0;
  display: block;
  transition: $transition box-shadow;
  cursor: pointer;

  &__thumb{
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center!important;
    justify-content: center!important;
    max-width: 400px;
    max-height: 340px;

    &__image {
      overflow: hidden;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: auto;
    }
  }
}
