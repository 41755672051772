@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali:wght@400;700&display=swap');

:root {
  --adm-color-primary: #F2651E;
  background-color: #f0f0f0;
}

// Font
$root-font: 'Roboto', sans-serif;
$title-font: 'Open Sans', sans-serif;

// Background Color
$bgc-body: #f8f8f8;
$bgc-button: #62842C;
$product-thumb-bg: rgba($color: #88B34C, $alpha: 0.10);
$brand-color: #F26623;
$brand-color-light: #FF5900;


// Color
$title-color: #242A18;
$txt-color: #5D6154;
$txt-light-color: #878A81;
$hover-color: #88B34C;


// Primary Border
$primary-border: 1px solid;


// Link Transition
$transition: 300ms ease-in-out;

// Section Padding
$section-padding: 6.25rem;

// Text Overwrap
%txt-overlap {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Button Style
%button {
  border: none;
  outline: none;
  transition: 400ms linear;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.adm-page-indicator-color-white {
  --active-color: #FF5900!important;
}

.title-text{
  font: 0.9em PingFang SC,microsoft yahei,STHeitiSC-Light,simsun,sans-serif;
}

.login_image_view{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.adm-list-default{
  border: none!important;
}

.loading_middle{
  display: flex;
  align-items: center;
  justify-content: center;
}

.shipping_details_spe{
  font-size: 0.9em!important;
  color: $bgc-button;
  font-weight: bold;

  &__rate{
    color: $brand-color-light;
    font-weight: bold;
  }
}

.key_word_link{
  text-decoration: none!important;
}
