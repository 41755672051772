@import "src/global/global.scss";
@import "src/global/abstracts/_mixins.scss";

.search_history{
  padding: 4% 1% 1% 1%;

  @include min-media-width(1199) {
    margin-top: 20%;
  }

  .search_take_down{
    align-items: center;

    &__search_text{
      color: #82857a;
    }

    &__search_clear{
      color: $brand-color-light;
    }
  }

  .search_makeup{
    margin-top: 6%;

    button{
      background-color: #e8e8e8;
    }
  }
}
