//.adm-error-block-full-page{
//  padding: calc(4vh - 10px) 0;
//}

.error_button{
  padding: 5% 10%;
}

.error_block{
  position: absolute;
  top: 30%;
  left: 5%;
  transform: translateX(0%) translateY(-50%);
  right: 5%;
}
