@import "src/global/global.scss";
@import "src/global/abstracts/_mixins.scss";

.search_list_details{

}

.product_filter_value{
  position: fixed;
  top: 60px!important;
  z-index: 999;
  background-color: white;
  width: 100vw;

  @include min-media-width(767) {
    width: 500px!important;
    margin: 0 auto!important;
    left: 0!important;
    right: 0!important;
  }
}

.search_dropdown{

  .ho_gap{
    --gap-vertical: 20px!important;

    &:last-child{
      .adm-divider{
        display: none;
      }
    }
  }

  .adm-divider{
    margin: 6px 0!important;
  }

  .radio_search{
    --icon-size: 22px;
    --font-size: 17px;
  }

  //.adm-radio{
  //  .adm-radio-content{
  //    font-size: 16px!important;
  //  }
  //
  //  .adm-radio-icon{
  //    width: 16px!important;
  //    height: 16px!important;
  //  }
  //}
}

.search_results {
  padding: 0 2%;

  .masonry_grid{
    max-width: 25rem;
    margin: 0 auto;
    //columns: 100px 2!important;
    //display: block;
    //column-gap: 0.6em;

    /*display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    height: 200rem;

    &::before,
    &::after {
      content: "";
      flex-basis: 100%;
      width: 0;
      order: 2;
    }*/

    /*display: grid;
    gap: 0.5em;
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
    grid-auto-rows: 20em;*/

    .search_results_grid{
      background-color: white;
      border-radius: 8px;
      //margin-bottom: 0.5em;
      //float: left;

      //&:nth-child(2n+1) {
      //  clear: left;
      //}

      /*width: 49%;
      &:nth-child(odd) { order: 1; }
      &:nth-child(even) { order: 2; }*/

      /*&:nth-child(3n) {
        grid-column: span 1;
        grid-row: span 2;
      }*/
    }
  }

  .masonry_grid > * {
    break-inside: avoid;
  }


  @supports (grid-template-rows: mesonry) {
    .masonry_grid{
      max-width: 25rem;
      margin: 0 auto;
      grid-template-columns: repeat(2, 1fr) !important;
      grid-template-rows: mesonry;
    }
  }
}

.image_search_results{
  .adm-image{
    border: 2px solid #F26623;
    border-radius: 8px;
    box-shadow: 2px 2px 0 1px rgba(242,102,35,0.65);
    max-width: 100px;
    max-height: 100px;

    .adm-image-img{
      width: 100px;
      height: auto;
      border-radius: 8px;
    }
  }
}

.image_search_results_title{
  margin-left: 5%;
  &__value{
    font-size: 1.4em;
    color: white;
    font-weight: bold;
  }
}

.more_filter{
  padding: 0 3%;
  color: $brand-color-light;
  font-weight: bold;

  .adm-checkbox-content{
    font-size: 13px!important;
  }

  .adm-checkbox-icon{
    width: 18px;
    height: 18px;
  }
}

.search_filter{
  padding: 0 1% 0 0;
  align-items: center!important;

  .search_filter_slider{
    padding: 2%;

    &__filter{
      font-size: 18px!important;
    }

    &__icons{
      font-size: 24px!important;
    }

    &__border_left{
      font-size: 24px!important;
      color: #bfbfbf;
    }

    &__title{
      font-size: 18px!important;
      color: $brand-color-light;
      font-weight: bold;
      animation: color-change 1s infinite;
    }

    @keyframes color-change {
      0% { color: #a0d911; }
      50% { color: #13c2c2; }
      100% { color: #f5222d; }
    }

    //span{
    //  font-size: 16px!important;
    //}
  }
  .adm-dropdown-item{
    flex: none!important;
  }

  .adm-dropdown-item-title{
    font-size: 1.2em!important;
  }
}
