.product_main_variation{
  margin-right: 3%;
  margin-top: 3%;

  .adm-space{
    &:first-child{
      margin-left: 0!important;
      background-color: black;
    }
  }

  button{
    padding: 0!important;
  }

  &__button{
    background-color: #f7f7f7;
    --background-color: #f7f7f7;
    border-radius: 8px;
    height: 62px!important;
    --border-color: #808200!important;
  }
}

.comma{
  font-weight: bold;
}

.comma:not(:empty) ~ .comma:not(:empty):before {
  content: ", ";
}
