@import "src/global/global.scss";

.otp_login_form{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 5% 16% 5%;

  .otp_login{

    &__main_title{
      font-size: 1.5em;
      color: $brand-color-light;
      font-weight: bold;
    }


    &__title{
      font-size: 16px;
    }

    &__value{
      font-weight: bold;
      font-size: 16px;
    }
  }

  .otp-input{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3% 0;

    &__title{
      padding: 0 4% 4% 4%;
      font-size: 16px;
    }

    .otp_resend_code{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2em;
      font-weight: bold;
      margin-top: 10%;
    }

    .otp_submit{
      padding: 4%;
    }
  }
}

.otp_cell{
  --cell-gap: 20px;
  --cell-size: 60px;
}
