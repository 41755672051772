.product-thumb {
  width: 80px;
  min-height: 80px;
  position: relative;
  flex-shrink: 0;
  padding: 1px;
  //border: 1px solid #ECECEC;
  border-radius: 5px;
  background-color: white;
  top: -33px;

  .img-fluid{
    max-width: 100%;
    height: auto;
  }

  //@include media-width(640) {
  //  width: auto;
  //  height: auto;
  //  margin-bottom: 0.5rem;
  //  display: flex;
  //  justify-content: center;
  //}
}
