@import "src/global/global.scss";

.login_register_take{
  margin-bottom: 2%;

  .login_grid{
    margin-top: 3%;
    margin-bottom: 36%;
  }
}

.login{
  height: 100px;
  padding: 2%;
  display: flex;
  justify-content: center;
  flex-flow: column;

  &__title{
    font-size: 1.6em;
    font-weight: bold;
    padding: 0 1%;
    color: $brand-color-light;
  }

  .social_login{
    margin-top: 8% !important;

    &__button{
      display: flex;
      align-items: center;
      background-color: #E6E6E6;
      color: black;
      font-size: 16px!important;
      --border-radius: 5px!important;
      width: 193px;

      span{
        margin-left: 6%;
      }
    }

  }
}

.divider{
  color: black;
  border-color: #D5D5D5;
}

.login_form{

  .adm-list{
    --padding-left: 14px!important;
  }

  .adm-list-item-content{
    border-bottom: none!important;
    border-top: none!important;
  }
  .adm-list-default{
    border-top: none!important;
    border-bottom: none!important;
  }

  .adm-list-item-title{
    margin-bottom: 4%;
  }

  .adm-input{
    --font-size : 16px;
    border: 2px solid #E9E8E7;
    border-radius: 8px;

    .adm-input-element{
      padding: 3% !important;
    }
  }
}

.forget_passport{
  padding: 1% 4% 4% 1%;
  text-align: right;
  color: $brand-color-light;
  font-weight: bold;
}

.login_with_google{
  display: flex;
  align-items: center;
  justify-content: center;
  button{
    padding: 0!important;
    --border-width: 0;
  }
}
