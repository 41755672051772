@import "src/global/global.scss";
$transition: 300ms ease-in-out;

.product-search-grid-item {
  margin-bottom: 4%;
}

.product_search_just_for_you {
  cursor: pointer;
  .product_search_image{
    background-color: #EEEEEE;
    padding: 0;
    display: block;
    transition: 300ms ease-in-out box-shadow;
    cursor: pointer;
    border-radius: 4px;

    &__thumb{
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      border-radius: 4px;

      &__image {
        max-width: 240px;
        max-height: 240px;
        overflow: hidden;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 4px;
      }

      &__status-top, &__status-bottom, &__wish {
        position: absolute;
        z-index: 1;
      }

      &__status-top, &__status-bottom {
        left: 0.1rem;
        color: #fff;
        font-size: 0.75rem;
        line-height: 1rem;
        padding: 0.2rem 0.325rem 0.2rem 0.3125rem;

        &:after {
          content: '';
          position: absolute;
          left: 100%;
          top: 0;
        }
      }

      &__status-top {
        top: 0;
        left: 0;
        background-color: #13c2c2;
        text-transform: uppercase;

        &:after {
          border-left: 0.375rem solid #13c2c2;
          border-right: 0 solid transparent;
          border-bottom: 0.7rem solid transparent;
          border-top: 0.7rem solid transparent;
        }
      }

      &__wish {
        top: 0.1rem;
        right: 0.15rem;

        button {
          @extend %button;
          background-color: #fff;
          width: 1.6625rem;
          height: 1.6625rem;
          line-height: 1.7625rem;
          font-size: 0.875rem;
          text-align: center;
          border-radius: 50%;
          color: $txt-color;

          &.active, &:hover {
            background-color: #FF3D00;
            color: #fff;
          }
        }
      }
    }
  }

  .product_search_price{
    margin-left: 3%;
    &__sold{
      font-size: 0.9em;
      font-weight: bold;
      color: $brand-color;
    }

    &__price{
      font-weight: bold;
      color: $title-color;
      font-size: 1.4em;
    }

    &__del{
      font-weight: bold;
      font-size: 0.9em;
      color: #5D6154 !important;
    }

    &__offer{
      font-weight: bold;
      color: $brand-color;
    }
  }
}

.product_search_title{
  margin-bottom: 5%;
  margin-left: 3%;
  span {
    color: $txt-color;
    @extend %txt-overlap;
    transition: $transition color;
    cursor: pointer;

    &:hover {
      color: $hover-color;
    }
  }
}

.product_search_rating{
  margin-left: 3%;
  span{
    display: flex;
    align-items: center;
  }
}

.extra{
  margin-bottom: 0!important;
}
