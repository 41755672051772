@import "src/global/global.scss";
.top-search-bar {
  //text-align: center !important;
  //align-items: center !important;
  // background-image: linear-gradient(0deg, rgba(246,145,29,1) 0%, rgba(242,101,30,1) 100%);
  background-color: $brand-color;
  padding: 4% 4% 0;
  transition: .5s;

  &__icon{
    font-size: 24px!important;
    color: white;
  }

  &__title{
    color: white;
    font-size: 1.3em;
    padding: 2% 0 0;
  }

  &__sub_title{
    font-size: 0.9em;
    color: #ffc69e;
  }

  &__icon-size {
    font-size: 30px;
    color: white;

    span {
      margin-top: 5px;
    }
  }

  &__search-bar {
    padding: 2%;
  }
}

.home_search_box {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: $brand-color;
  position: sticky;
  top: 0;
  padding: 10px 4%;
  z-index: 9;
}

.swiper-images-homepage {
  .product_details_image_homepage {
    width: 100% !important;
    //min-height: 390px !important;
    position: relative;
    flex-shrink: 0;
    background-color: #f0f0f0;
    display: block;
    transition: $transition box-shadow;
    cursor: pointer;

    &__thumb {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      max-width: 500px;
      max-height: 350px;

      &__image {
        overflow: hidden;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
      }
    }
  }
}

.product_details_image_slider{
  height: 115px;
  width: 100%;
  &__value{
    height: auto;
    width: 100%;
    .slider-image {
      max-width: 100% !important;
      height: 100% !important;
      border-radius: 4px;
      width: 100% !important;
      --width: 100% !important;
      --height: 100% !important;
      .adm-image-img{
        width: 100% !important;
        height: 100% !important;
        overflow: hidden;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.icon-pack {
  background-color: #e6f7ff;
  padding: 4%;
  border-radius: 5px;

  &__icon-homepage {
    text-align: center;

    .services_icon_image{
      width: 44px!important;
      --width: 44px!important;
      height: 44px!important;
      --height: 44px!important;
      .adm-image-img{
        width: 44px!important;
        height: 44px!important;
      }
    }

    .homepage-icon-text {
      font-size: 1.2em;
      color: black;
    }
  }
}

.flash-deal-view-all {
  font-size: 0.875em;
  // color: $brand-color;
}

.category_grid {
  align-items: center !important;
}

.flash_deal_grid {
  align-items: center !important;
}

.payment_verify {
  padding: 2%;
  background-color: #f5f5f5;
  margin-top: 4%;
  border-radius: 5px;

  &__cost_calculator {
    text-align: right;
  }

  &__text {
    font-size: 10px;
  }
}

.flash_deal_swiper {
  .adm-swiper-indicator {
    display: none !important;
  }
}

.ads_slider_image {
  background-color: #EEEEEE;
  padding: 0;
  display: block;
  transition: 300ms ease-in-out box-shadow;
  cursor: pointer;
  border-radius: 4px;

  &__thumb {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 4px;

    &__image {
      max-width: 240px;
      max-height: 240px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 4px;
      .adm-image-tip{
        width: 184px!important;
        height: 80px!important;
      }
      .adm-image-img{
        width: 184px!important;
        height: 80px!important;
      }
    }
  }
}

.searchbar_title_image{
  width: 100px!important;
  --width: 100px!important;
  height: 40px!important;
  --height: 40px!important;
  .adm-image-img{
    width: 100px!important;
    height: auto!important;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search_bar_fixed{
  align-items: center!important;
  background-color: $brand-color-light;
  padding: 2%;
  position: fixed;
  top: 0!important;
  z-index: 999;
  width: 100vw;
  transition: opacity .5s ease-in-out;
}

.sticky_searchbar{
  // background-image: linear-gradient(0deg, rgba(246,145,29,1) 0%, rgba(242,101,30,1) 100%);
  background-color: var(--adm-color-primary);
  position: fixed;
  z-index: 999;
  top: 0!important;
  width: 96vw;
  padding: 2%;
  transition: .5s;
}

@keyframes sticky_searchbar {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
