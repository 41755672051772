@import "src/global/global.scss";

.profile_image {
  font-size: 36px;
}

.profile_details {
  background-image: linear-gradient(to right, $brand-color, #F6911D);

  .adm-nav-bar {
    --height: 60px;
    color: white;
    font-weight: bold;
  }
}

.profile_list_item {

  .adm-list-default {
    border: none !important;
  }
}

.profile_name {
  padding: 6% 0;

  .adm-list-item-content-extra{
    margin-top: 8%;
  }

  .shipping_address {

    .adm-input, .adm-text-area {
      --font-size: 16px;
      border: 1px solid #E9E8E7;
      border-radius: 8px;

      .adm-input-element {
        padding: 2% 4% !important;
      }

      .adm-text-area-element {
        padding: 3% !important;
      }
    }

    .adm-list-item-content {
      border-bottom: none !important;
      border-top: none !important;
    }
  }

  .profile_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3%;

    &__title {
      font-weight: bold;
      font-size: 16px;
    }

    &__icons {
      font-size: 18px;
    }
  }

  .profile_form {
    margin-top: 3%;

    .adm-list-item-content{
      border-top: none !important;
    }

    .adm-input {
      --font-size: 16px;
      border: 1px solid #E9E8E7;
      border-radius: 8px;

      .adm-input-element {
        padding: 2% 4% !important;
      }
    }
  }
}

.right_icon {
  font-size: 24px;
}

.shipping_grid {

  .shipping_flex {
    display: flex;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
  }

  &__address {
    margin-left: 4%;
  }

  &__book {
    font-size: 15px;
  }

  &__phone, &__address {
    font-size: 15px;
  }
}

.add_new_address {
  bottom: 51px;
  position: fixed;
  width: 100%;
}

.shipping_title {
  font-size: 1.3em;
  font-weight: bold;
  color: $brand-color-light;
}

.shipping_icons {
  margin-top: 50%;
}

.check_list_map {
  //margin-top: 2%;

  .adm-divider {
    margin: 10px 0 !important;
  }

  .space_country {
    padding: 0 3%;
    align-items: center !important;
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3% 3% 0 3%;
    color: $brand-color-light;

    &__text {
      font-size: 1rem;
    }

    &__icon_text {
      font-size: 1.3rem;
    }
  }
}

.address_border {
  &:last-child {
    .adm-divider {
      display: none;
    }
  }
}

.address_form_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notice{
  --height: 26px!important;
  --font-size: 0.8em!important;
  --icon-font-size: 14px!important;
}
