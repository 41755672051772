.count_down_format{
  background-color: #120106;
  border: 2px solid #2A2627;
  padding: 10% 20%;
  border-radius: 4px;
  --gap: 0!important;

  &__title{
    color: #F5F5F5;
    font-size: 1.7em;
    font-weight: bold;
  }

  &__sub_title{
    color: #F5F5F5;
    font-size: 0.9em;
  }
}

.dot{
  font-size: 2em;
}

.countdown-title{
  font-size: 1.5em;
  font-weight: bold;
  color: #F5F5F5;
  text-align: center;
  margin-bottom: 2% !important;
  text-transform: uppercase;
}
