.campaign_image{
  background-color: #EEEEEE;
  padding: 2%;
  transition: 300ms ease-in-out box-shadow;
  border-radius: 8px;

  &__thumb{
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    .adm-image{
      border-radius: 8px;
    }

    &__timer {
      position: absolute;
      z-index: 1;
    }

    &__timer {
      top: 0;
      right: 0;
      color: #fff;
      line-height: 1rem;
      padding: 0 0.12rem 0 0.31rem;
      text-transform: uppercase;

      &:after {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
      }
    }
  }
}

.masonry_grid{
  max-width: 25rem;
  margin: 0 auto;

  .search_results_grid{
    background-color: white;
    border-radius: 8px;
  }
}

