@import "global/global.scss";

.payment_type {
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 4%;
}

.payment_selector {
  padding: 4% 0 4% 6%;

  .adm-selector-item {
    border-radius: 8px;
  }
}

.payment_ex {
  margin-top: 5%;
}

.payment_title {
  font-size: 1.2em;
  margin-top: 4%;

  &__value {
    font-weight: bold;
    color: $brand-color;
  }
}

.payment_des {
  font-size: 1em;
  color: $brand-color_light;
}
