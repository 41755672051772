.specification{
  padding: 2% 2%;
  align-items: center;

  &__title{
    font-size: 18px!important;
    font-weight: bold;
  }

  &__icons{
    font-size: 16px;
  }
}

.list_value{
  display: flex;
  //justify-content: space-between;
  //align-items: center;

  &__text{
    color: #747474;
    font-size: 14px!important;
    flex-grow: 1;
  }

  &__value{
    text-align: right;
  }
}
