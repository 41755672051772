.header-back{
  align-items: center;
  padding: 1% 0;
  &__icons{
    font-size: 16px;
    //color: white;
  }

  &__text{
    font-size: 16px;
    font-weight: bold;
  }
}

.popup_header{
  padding: 5%;
  text-align: center;

  &__icon{
    font-size: 22px;
    text-align: center;
  }

  &__text{
    font-size: 16px;
    font-weight: bold;
  }
}

.popup_button{
  border-radius: 25px;
  text-align: center;
  border-color: #62842C;
}
