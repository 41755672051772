@import "src/global/abstracts/_mixins.scss";

.tab-bar{
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 999;
  border-top: 1px solid #efefef;

  @include min-media-width(767) {
    width: 500px!important;
    margin: 0 auto!important;
    left: 0!important;
    right: 0!important;
  }
}
