@import "src/global/global.scss";

.wish_list_divider{
  &:last-child{
    .adm-divider{
      display: none;
    }
  }
}

.wish_list {
  margin-left: 2%;
  margin-bottom: 5%;

  .wish_list_image {
    &__thumb {
      width: 100%;
      height: auto;
      border-radius: 8px;
      border: 1px solid #f0f0f0;
    }
  }

  .wish_list_product_details {

    .wish_list_qty {
      margin-top: 2%;
    }

    &__price {
      font-size: 16px;
      font-weight: bold;
    }

    &__icons{
      font-size: 18px!important;
    }

    &__title {
      a{
        text-decoration: none;
      }
      span {
        color: $txt-color;
        @extend %txt-overlap;
        transition: $transition color;
        cursor: pointer;

        &:hover {
          color: $hover-color;
        }
      }
    }
  }
}
