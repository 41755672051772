@import "src/global/global.scss";

.cart_layout_all_select{
  padding: 0 2% !important;
}

.seller_layout{
  padding: 2%;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.cart_layout {
  align-items: center;
  margin-bottom: 5%;

  .store_title {
    font-size: 14px;
    font-weight: bold;
    color: $brand-color;
  }

  .cart_delete {
    text-align: right;

    &__icon {
      font-size: 16px;
    }
  }

  .cart_item_checkbox {
    input[type=checkbox] {
      /* Double-sized Checkboxes */
      -ms-transform: scale(1.1); /* IE */
      -moz-transform: scale(1.1); /* FF */
      -webkit-transform: scale(1.1); /* Safari and Chrome */
      -o-transform: scale(1.1); /* Opera */
      transform: scale(1.1);
      padding: 10px;
      filter: hue-rotate(150deg)
    }
  }
}

.cart_product_layout {
  margin-left: 2%;
  margin-bottom: 2%;
  .cart_product_layout_checkbox {
    height: 90px;
    position: relative;

    .cart_item_checkbox {
      margin: 0;
      position: absolute;
      top: 23%;

      input[type=checkbox] {
        /* Double-sized Checkboxes */
        -ms-transform: scale(1.1); /* IE */
        -moz-transform: scale(1.1); /* FF */
        -webkit-transform: scale(1.1); /* Safari and Chrome */
        -o-transform: scale(1.1); /* Opera */
        transform: scale(1.1);
        padding: 10px;
        filter: hue-rotate(150deg)
      }
    }
  }

  .cart_product_image {
    &__thumb {
      width: 100%;
      height: auto;
      border-radius: 8px;
      border: 1px solid #f0f0f0;
    }
  }

  .cart_product_details {
    .cart_attr{
      font-size: 0.9em;
      color: $txt-color;
    }
    .cart_attr:not(:empty) ~ .cart_attr:not(:empty):before {
      content: ", ";
    }
    .cart_qty {

      .price_qty {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__stepper {
          width: 100px;
        }

        &__note {
          text-decoration: underline;
        }
      }

    }

    &__price {
      font-size: 14px;
      font-weight: bold;
    }

    &__title {
      a{
        text-decoration: none;
        font-size: 1em!important;
      }
      span {
        color: $txt-color;
        @extend %txt-overlap;
        transition: $transition color;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
          color: #0645AD;
        }
      }
    }
  }
}

.checkout_des_text{
  font-size: 0.8em;
  color: $txt-color;
}

.grid_value_add{
  margin-bottom: 4%;
  color: black;
  font-size: 1.3em;
}

.grid_long_text{
  margin-top: 5%;
  color: $txt-color;
}

.checkout_details{
  padding: 4% 4% 0 4%;
  background-color: white;
  margin-bottom: 4%;
}

.checkout_address_grid{
  padding: 3% 4% !important;

  &__title{
    font-size: 16px;
    font-weight: bold;
  }

  &__phone, &__address{
    font-size: 15px;
  }
}

.checkout_address_bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 3% 0 3%;
    color: $brand-color-light;

    &__text{
      font-size: 1rem;
      font-weight: bold;
    }

    &__icon_text{
      font-size: 1.3rem;
    }
}

.checkout_address_details{
  .adm-divider{
    margin: 8px 0;
  }

  .adm-list-item-content-main{
    padding: 0!important;
  }

  .adm-list-item {
    padding-left: 0 !important;
  }
}

.seller_group{
  font-size: 14px;
  font-weight: bold;
  color: $brand-color;
}

.cart_full_layout{
  .grid_bottom{
    align-items: center!important;

    .shipping_charges{
      font-size: 1.1em;
      color: #597ef7;
      font-weight: bold;
    }
  }
  &:last-child{
    .adm-divider{
      display: none;
    }
  }
}

.note_form{
  margin-top: 2%;

  .adm-text-area{
    --font-size : 16px;
    border: 1px solid #E9E8E7;
    border-radius: 8px;

    .adm-text-area-element{
      padding: 2%;
    }
  }
}

.view_note{
  border: 1px solid #bfbfbf;
  padding: 3%;
  border-radius: 8px;
}

.note_button{
  border: 1px solid $brand-color-light;
}

.additional_note_size{
  font-size: 1.3em;
}

.additional_note{
    --font-size : 16px;
    border: 1px solid #E9E8E7;
    padding: 2%;
    border-radius: 8px;
}

.shipping_charges{
  font-size: 0.9em;
  color: $txt-color;
  font-weight: bold;
}

.add_new_address_view{
  //padding: 3% 3%;
  text-align: right;
  color: $brand-color-light;
  font-size: 1.1em;
}
