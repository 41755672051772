@import "src/global/global.scss";
$transition: 300ms ease-in-out;


.product-grid-item {
  width: 100% !important;
  min-height: 220px !important;
}



.product_keyword_search {
  //max-width: 100%;
  //max-height: 100%;
  cursor: pointer;

  .wish_product_list{
    &__exist {
      margin-left: 39.4%;
      margin-top: 0.6%;
      position: absolute;
      z-index: 1;

      button {
        @extend %button;
        background-color: #FF3D00;
        width: 1.5625rem;
        height: 1.5625rem;
        line-height: 1.5625rem;
        font-size: 0.875rem;
        text-align: center;
        border-radius: 50%;
        color: #fff;

        &.active, &:hover {
          background-color: #FF3D00;
          color: #fff;
        }
      }
    }

    &__wish {
      position: absolute;
      z-index: 1;
      margin-left: 39.4%;
      margin-top: 0.6%;
      button {
        @extend %button;
        background-color: #fff;
        width: 1.6625rem;
        height: 1.6625rem;
        line-height: 1.7625rem;
        font-size: 0.875rem;
        text-align: center;
        border-radius: 50%;
        color: $txt-color;

        &.active, &:hover {
          background-color: #FF3D00;
          color: #fff;
        }
      }
    }
  }

  .product_keyword_search_image{
    background-color: #EEEEEE;
    padding: 0;
    display: block;
    transition: 300ms ease-in-out box-shadow;
    cursor: pointer;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    //max-width: 14.9em;
    //height: 175px;

    &__thumb{
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;

      &__image {
        max-width: 240px;
        max-height: 240px;
        overflow: hidden;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5%;
      }

      &__status-top, &__status-bottom, &__wish {
        position: absolute;
        z-index: 1;
      }

      &__status-top, &__status-bottom {
        left: 0.1rem;
        color: #fff;
        font-size: 0.75rem;
        line-height: 1rem;
        padding: 0.2rem 0.325rem 0.2rem 0.3125rem;

        &:after {
          content: '';
          position: absolute;
          left: 100%;
          top: 0;
        }
      }

      &__status-top {
        top: 0;
        left: 0;
        background-color: #DC2626;
        text-transform: uppercase;

        &:after {
          border-left: 0.375rem solid #DC2626;
          border-right: 0 solid transparent;
          border-bottom: 0.7rem solid transparent;
          border-top: 0.7rem solid transparent;
        }
      }

      &__status-bottom {
        bottom: 0;
        left: 0!important;
        background-color: #FE9801;
        text-transform: uppercase;
        font-size: 0.77rem!important;

        &:after {
          border-left: 0 solid transparent;
          border-right: 0.5rem solid transparent;
          border-bottom: 0 solid transparent;
          border-top: 1.6rem solid #FE9801;
        }
      }
    }
  }

  .product_keyword_search_price{
    padding: 0 5% 5% 5%;
    &__sold{
      font-size: 0.9em;
      font-weight: bold;
      color: $title-color;
    }

    &__del{
      font-weight: bold;
      font-size: 0.9em;
      color: #5D6154 !important;
      margin-left: 10px;
    }

    &__price{
      font-weight: bold;
      color: $title-color;
      font-size: 1.4em;
    }

    &__offer{
      font-weight: bold;
      color: $brand-color;
      font-size: 12px;
    }
  }
}

.product_keyword_search_title{
  padding: 0 5%;
  span {
    color: $txt-color;
    @extend %txt-overlap;
    transition: $transition color;
    cursor: pointer;

    &:hover {
      color: $hover-color;
    }
  }
}

.product_keyword_search_rating{
  span{
    display: flex;
    align-items: center;
  }
}
