@import "src/global/global.scss";

.re_product_grid {
  align-items: center !important;

  &__view-all {
    font-size: 0.875em;
    color: $brand-color;
  }

}

.swiper_value{
  .adm-swiper-track-inner{
    transform : translate3d(0px, 0px, 0px)!important;
  }
}
