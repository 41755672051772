@import "src/global/global.scss";

.global_header_back{
  //background-image: linear-gradient(0deg, rgba(246,145,29,1) 0%, rgba(242,101,30,1) 100%);
  position: sticky;
  top: 0;
  z-index: 999;

  .adm-nav-bar{
    --height : 60px;
    color: white;
    font-weight: bold;
  }
}

.global_right_icon{
  font-size: 24px;
}

.global_left_title{
  font-size: 1.27em;
  display: block;
  white-space: nowrap;
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.global_popup_header{
  padding: 3%;
  text-align: center;
  color: $txt-color;

  &__icon{
    font-size: 18px;
    text-align: center;
  }

  &__text{
    font-size: 1em;
    font-weight: bold;
  }
}

.global_popup_button{
  border-radius: 25px;
  text-align: center;
  border-color: $bgc-button;
}
