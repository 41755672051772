@import "src/global/global.scss";
.manual_grid{
  align-items: center;

  &__title{
    font-size: 1em;
    @extend %txt-overlap;
    -webkit-line-clamp: 2;
  }

  &__price{
    font-size: 1.4em;
    font-weight: bold;
  }

  &__button{
    text-align: start!important;
    --background-color: rgb(12, 162, 194, 3%);
    --border-color: rgb(12, 162, 194, 30%);
    --border-width: 2px;
  }

  &__view{
    background-color: #F5F5F5;
    padding: 5% 2%;
    border-radius: 8px;
  }

}

.request_now_button{
  --border-width: 2px;
  --border-color: rgb(242, 101, 30, 100%);
  font-weight: bold;
  color: black;
}

.view_product_button{
  background: linear-gradient(0deg, rgba(246,145,29,1) -20%, rgba(242,101,30,1) 70%) !important;
}
