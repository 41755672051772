.image-container {
  background-color: #ccc;
  overflow: hidden;
  position: relative;
  max-width: 240px;
}
.image {
  position: absolute;
  width: 100%;
  opacity: 0;
  max-width: 100%;
  height: auto;

  &.thumb {
    opacity: 1;
    filter: blur(10px);
    transition: opacity 0.5s ease-out;
    position: absolute;
    &.isLoaded {
      opacity: 0;
    }
  }

  &.isLoaded {
    transition: opacity 0.5s ease-out;
    opacity: 1;
  }
}
