@import "src/global/global.scss";

.rating_divider{
  &:last-child{
    .adm-divider{
      display: none;
    }
  }
}

.rating_review {
  padding: 3% 1%;

  .product_rating{
    &:nth-last-child(3){
      .adm-divider{
        display: none;
      }
    }
  }

  &__title {
    font-size: 1rem;
    font-weight: bold;
  }

  .rating_image {
    margin-top: 3%;

    .rating_view_space{
      padding: 0 1%;
    }

    &__thumb{
      border-radius: 5px;
    }
  }

  .rating_content {
    margin-top: 3%;
    font-size: 0.875rem;
    font-weight: bold;
  }

  .rating_details {
    margin-top: 5%;

    &__date {
      color: $txt-color;
    }

    &__meta {
      color: $txt-color;
      font-size: 0.813rem;
    }

    &__name {
      color: black;
      font-size: 0.875rem;
    }

  }
}
