@import "src/global/global.scss";

.loading_value{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 75% 0 105% 0;
  background: #f0f5ff;

  &__color-picker{
    color: $brand-color;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .adm-loading{
      font-size: 1.7em!important;
    }
    span{
      font-weight: bold;
      font-size: 4em;
    }
  }
}
