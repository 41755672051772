@import "src/global/abstracts/_mixins.scss";
@import "src/global/global.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include min-media-width(767) {
    width: 500px!important;
    margin: 0 auto!important;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.adm-popup-body{
  @include min-media-width(767) {
    width: 500px!important;
    margin: 0 auto!important;
    right: 0
  }
}

.link_underline{
  text-decoration: none;
}

.view_more_customer_order_review{
  margin-left: 0.6%;
  margin-top: 2%;

  .customize_order{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $brand-color-light;
    font-weight: bold;

    &__title{
      margin-left: 4px!important;
      font-size: 1em!important;
    }

  }

  .adm-divider{
    margin: 2px 0!important;

    .adm-divider-content{
      padding: 0 12px!important;
    }
  }

  span {
    font-size: 16px;
  }
}

.view_more_customer_order{
  margin-left: 0.6%;
  margin-top: 2%;

  .customize_order{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #52c41a;
    font-weight: bold;

    &__title{
      margin-left: 4px!important;
      font-size: 1em!important;
    }

  }

  .adm-divider{
    margin: 2px 0!important;
    border-style: none!important;

    &:after{
      max-width: 0!important;
    }

    .adm-divider-content{
      padding: 0 12px!important;
    }
  }

  span {
    font-size: 16px;
  }
}

.text_align{
  text-align: right;
}

.payment_total{
  margin-left: 2%;
  font-weight: bold;
  color: $brand-color-light;
  font-size: 1.2em;
  text-align: center;
}

.payment_form{
  .adm-input-wrapper{
    --font-size : 16px;
    border: 1px solid #E9E8E7;
    border-radius: 8px;

    .adm-input{
      padding: 2% !important;
    }
  }

  .adm-list-item-content{
    border-bottom: none!important;
  }
}

.payment_internal{
  font-size: 1.4em;
  font-weight: bold;
  color: $brand-color-light;
}

.payment_internal_title{
  font-size: 1.2em;
  font-weight: bold;
}

.payment-content{
  background-color: white;
  padding: 3% 0 11% 0;
  margin-top: 3%;

  &__button{
    margin: 0 3%;
  }

  .adm-input{
    --font-size : 16px;
    border: 1px solid #E9E8E7;
    border-radius: 8px;

    .adm-input-element{
      padding: 2%;
    }
  }

  .adm-list-item-content{
    border-bottom: none!important;
    border-top: none!important;
  }
}

.grid_margin{
  margin-top: 5%;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

//.adm-toast-wrap{
//  background-color: #F5F5F5!important;
//  color: black!important;
//  font-weight: bold!important;
//}

.adm-list-default .adm-list-body{
  border-top: none!important;
  border-bottom: none!important;
}
