// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts

@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}


@mixin clearfix {
  &::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  display: inline-block;
}

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
        'xs-small': 330px,
        'sm-small': 480px,
        'md-small': 576px,
        'lg-small': 767px,
        'medium': 991px,
        'large': 1024px,
        'x-large': 1199px,
);

@mixin media($media) {
  @media all and (max-width: #{map-get($breakpoints,#{$media})}) {
    @content
  }
}

@mixin media-width($media-width) {
  @media all and (max-width: #{$media-width}px) {
    transition: .5s; // animate when window resizing
    @content
  }
}

@mixin min-media-width($media-width) {
  @media all and (min-width: #{$media-width}px) {
    transition: .5s; // animate when window resizing
    @content
  }
}

//--------------------------------------------------------------------------------
// ------------ Metarial Design Boxshadow Mixin
//--------------------------------------------------------------------------------
// Created by @KingRayhan
// @url https://codepen.io/kingRayhan/pen/gWrKyz

@mixin mboxshadow($depth:1) {
  @if $depth == 1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $depth == 2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $depth == 3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $depth == 4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $depth == 5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}
