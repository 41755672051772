.search_filters_results{
  padding: 6% 3%;

  .bottom_button{
    position: absolute;
    bottom: 0;
    background-color: white;
    height: 5vh;
    z-index: 999;
    width: 280px;
  }

  &__filter_by{
    font-size: 18px;
    font-weight: bold;
  }

  .search_filters_form{
    margin-top: 4%;

    .adm-form-vertical .adm-form-item-label {
      margin-bottom: 8px!important;
    }

      .adm-list-default{
      border: none;
    }

    .adm-list{
      font-size: 15px!important;
      overflow-y: scroll;
      height: 92vh;
    }

    .adm-list-item{
      padding: 0;
    }

    .adm-list-item-content{
      //padding: 6px 12px 6px 0!important;
      border-bottom: none!important;
      border-top: none!important;
    }
  }
}
