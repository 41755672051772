.product-reviews-wrap {
  overflow: hidden;

  img{
    width: 100% !important;
    height: auto;
    padding: 2% 1%;
  }

  .detailmodule_image{
    padding: 2%;
    img{
      width: 100%;
      height: auto;
      padding: 2% 1%;
      border-radius: 7%;
    }
  }

  #ainv-reviews{
    padding: 0;
    margin: 0;
    list-style: none;
    .ainv-review{
      border: 1px solid #ebebeb;
      padding: 10px;
      margin-bottom: 20px;
      .ainv-avatar{
        margin-bottom: 1%;
      }
    }
    .ainv-product-info{
      margin-top: 1%;
      span{
        display: block;
      }
    }
    .ainv-review-content{
      margin-top: 1%;
      .ainv-review-msg{
        width: 50%;
      }
      .ainv-order-date{
        font-weight: bold;
        color: #88B34C;
        margin-left: 3%;
      }
    }
    .ainv-review-images {
      padding: 0;
      margin-top: 2%;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      li{
        display: inline-block;
        width: 100px;
        max-height: 150px;
        overflow: hidden;
        padding: 2px;
        background: #fff;
        border: 1px solid #ddd;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
