@import "src/global/global.scss";


.product_variation{
  padding: 3% 1% 0 3%;
  min-height: 85vh;

  .product_selected_variation_image{
    width: 90% !important;
    min-height: 100px !important;
    position: relative;
    flex-shrink: 0;
    display: block;
    transition: $transition box-shadow;
    cursor: pointer;
    border-radius: 8px;

    &__thumb {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      border: 1px solid #d7c7c7;
      border-radius: 8px;

      &__image {
        overflow: hidden;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .product-variation-thumb {
    width: 100px;
    min-height: 100px;
    position: relative;
    flex-shrink: 0;
    padding: 1px;
    border-radius: 5px;
    background-color: white;
    top: -33px;

    img{
      max-width: 100%!important;
      height: auto!important;
    }
  }

  .product_variation_divider{
    .adm-divider{
      margin: 12px 0!important;
    }
  }

  .product_variation_total{
    padding: 4% 2% 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    .total{
      color: $brand-color!important;
    }

    .available{
      color: $brand-color;
    }

    span{
      font: 15px/1.5 PingFang SC, microsoft yahei, STHeitiSC-Light, simsun, sans-serif;
      //color: black;
    }
  }

  .product_variation_number{
    align-items: center;
    padding: 6% 0 0 0;

    .button_stepper{
      --button-text-color: #f5222d!important;
      --button-font-size: 16px!important;
      --height: 28px!important;
      --active-border: 1px solid $brand-color!important;
      --input-width: 64px!important;
    }

    &__title{
      font-size: 1.2em!important;
    }
  }

  .product_variation_muti_select{
    padding: 2% 0 0 0;
    margin-right: 2%;

    &__image_button{
      padding: 4px 4px !important;
      border-radius: 8px!important;
      height: 50px;
    }

    &__image_button_hover{
      padding: 4px 4px !important;
      box-shadow: inset 0 0 0 1px $brand-color;
      border-radius: 8px!important;
      height: 50px;
    }

    &__title_button{
      padding: 8px 16px!important;
    }

    &__title_button_hover{
      padding: 8px 16px!important;
      box-shadow: inset 0 0 0 1px $brand-color;
    }
  }

  .product_variation_select{
    padding: 4% 0 0 0;
    align-items: end;

    &__text{
      font-weight: bold;
      &__gird{
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      &__label{
        margin-left: 2%;
        @extend %txt-overlap;
        color: $brand-color-light;
      }
    }
    &__button{
      button{
        padding: 4px 2px;
        font-size: 12px;
      }
    }
  }

  .adm-divider{
    margin: 12px 0!important;
  }

  .product_space{
    --vertical-size: 1px!important;
  }
  &__title{
    font: 12px/1.5 PingFang SC, microsoft yahei, STHeitiSC-Light, simsun, sans-serif;
    color: black;
  }

  &__des{
    font-size: 12px;
    color: $brand-color;
  }

  &__amount{
    font-size: 1.1em;
    font-weight: 700;
    color: $brand-color;
  }

  &__price{
    color: $txt-color !important;
    font-size: 1.1em;
  }
}

.shipping_step{
  padding: 0;
  .adm-step-content{
    padding-bottom: 10px!important;
  }
}

.shipping_list{
  padding: 0;

  .adm-steps{
    --title-font-size: 17px!important;
    --description-font-size: 15px!important;
  }
}

.shipping_method_no{
  font-size: 1.4em;
  .adm-list-item-content-main{
    padding: 0 10px!important;
  }
}

.product_dom{
  color: #08979c;
  font-size: 1.1em;
  font-weight: bold;
  font-style: italic;
}
