@import "src/global/global.scss";
$transition: 300ms ease-in-out;

.recommended-search-grid-item {
  width: 220px !important;
  min-height: 180px !important;
}

.recommended_list {
  max-width: 138px!important;
  max-height: 100%;
  cursor: pointer;
  .recommended_list_image{
    background-color: #EEEEEE;
    padding: 0;
    display: block;
    transition: 300ms ease-in-out box-shadow;
    cursor: pointer;
    border-radius: 5%;
    border: 1px solid #f7f7f7;

    &__thumb{
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      border-radius: 5%;

      &__image {
        max-width: 240px;
        max-height: 240px;
        overflow: hidden;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 5%;
      }

      &__status-top {
        position: absolute;
        z-index: 1;
      }

      &__status-top {
        left: 0.1rem;
        color: #fff;
        font-size: 0.75rem;
        line-height: 1rem;
        padding: 0 0.125rem 0 0.3125rem;

        &:after {
          content: '';
          position: absolute;
          left: 100%;
          top: 0;
        }
      }

      &__status-top {
        top: 0.2rem;
        left: 0.1rem;
        background-color: $brand-color;
        text-transform: uppercase;

        &:after {
          border-left: 0.375rem solid $brand-color;
          border-right: 0 solid transparent;
          border-bottom: 0.5rem solid transparent;
          border-top: 0.5rem solid transparent;
        }
      }
    }
  }

  .recommended_price{
    &__sold{
      font-size: 0.9em;
      font-weight: bold;
    }

    &__del{
      font-weight: bold;
      font-size: 0.8em;
      color: #5D6154 !important;
    }

    &__price{
      font-weight: bold;
      color: $brand-color-light;
      font-size: 1em;
    }

    &__offer{
      font-weight: bold;
      color: $brand-color;
      font-size: 12px;
    }
  }
}
