@import "global/global.scss";

.shipping_details{
  height: 90vh;
  overflow-y: scroll;
  margin-top: 1%;
  padding: 0 3%;

  .shippingChecklist{
    margin-top: 4%;
    .adm-list-body{
      background-color: #F5F5F5;
      padding: 0 2%;
      border-radius: 8px;
    }
  }

  .adm-divider{
    margin: 8px 0!important;
  }

  .adm-list{
    padding: 0 0 20% 0!important;
    --padding-left: 0!important;
  }

  &__icons{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1% 0 0 0;

    &__text{
      font-weight: bold;
      font-size: 1rem;
      color: $brand-color-light;
    }

    &__icon_text{
      font-size: 1rem;
    }
  }

  .shipping_details_space{
    --gap: 4px!important;
    &__title{
      font-size: 0.77em!important;

      &__tracking{
        font-size: 0.7em!important;
        font-weight: bold;
        color: #13c2c2;
      }
    }

    &__fee{
      font-weight: bold;
      font-size: 1em!important;
    }
  }
}

.shipping_notice{
  padding: 2%;
  background-color: #C4FCF0;
  margin: 2% 0;
  border-radius: 8px;

  &__notice_des{
    color: #55433B;
  }

}

.shipping_notice_cate{
  background-color: #ffbb96!important;
}

.shipping_method_title{
  margin-left: 1%;
  font-size: 1.3em;
  color: $brand-color;
  font-weight: bold;
}
