@import "src/global/global.scss";

.react-player{
  video{
    width: 100% !important;
  }
}

.video_play_icon{
  font-size: 50px;
  color: $brand-color-light;
  //background-color: #4169e1;
  //padding: 2%;
  //border-radius: 25px;
}

.image_gallery {
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  width: auto;
  height: 353px!important;

  .adm-swiper-track-inner {
    align-items: center!important;
  }
  .adm-swiper-indicator {
    bottom: 12px;
  }

  .adm-page-indicator-dot {
    width: 5px !important;
    height: 5px !important;
    border-radius: 5px !important;
  }

  .swiper-images {
    .product_details_image{
      width: 100% !important;
      //min-height: 390px !important;
      position: relative;
      flex-shrink: 0;
      background-color: #f0f0f0;
      display: block;
      transition: $transition box-shadow;
      cursor: pointer;

      &__thumb{
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center!important;
        justify-content: center!important;
        max-width: 411px;
        max-height: 370px;

        &__image {
          overflow: hidden;
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          height: auto;
        }

        &__status-top, &__status-bottom, &__wish, &__share {
          position: absolute;
          z-index: 1;
        }

        &__status-top, &__status-bottom {
          left: 0.1rem;
          color: #fff;
          font-size: 0.75rem;
          line-height: 1rem;
          padding: 0 0.125rem 0 0.3125rem;

          span{
            font-size: 1.200em;
          }

          &:after {
            content: '';
            position: absolute;
            left: 100%;
            top: 0;
          }
        }

        &__status-top {
          top: 0.6rem;
          left: 0.1rem;
          text-transform: uppercase;
          color: black;
          font-size: 20px;
        }

        &__share {
          top: 0.4rem;
          right: 3.5rem;

          span{
            font-size: 1.400em;
          }

          button {
            @extend %button;
            width: 1.9234rem;
            height: 1.9234rem;
            line-height: 1.7625rem;
            font-size: 1.3rem;
            text-align: center;
            border-radius: 50%;
            color: black;
            background: none!important;

            &.active, &:hover {
              background-color: #10239e;
              color: #fff;
            }
          }
        }

        &__wish {
          top: 0.5rem;
          right: 0.5rem;

          span{
            font-size: 1.400em;
            margin-top: 5px;
          }

          button {
            @extend %button;
            background-color: #fff;
            width: 1.9625rem;
            height: 1.9625rem;
            line-height: 1.7625rem;
            font-size: 0.875rem;
            text-align: center;
            border-radius: 50%;
            color: $txt-color;

            &.active, &:hover {
              background-color: #FF3D00;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
