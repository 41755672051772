@import "src/global/global.scss";

.filter_image {
  background-color: #EEEEEE;
  padding: 0;
  display: block;
  transition: 300ms ease-in-out box-shadow;
  cursor: pointer;
  border-radius: 5%;

  &__thumb {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 5%;

    &__image {
      max-width: 100px;
      max-height: 100px;
      overflow: hidden;
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5%;
    }
  }
}

.form_element {
  .adm-divider{
    margin: 8px 0!important;
  }
  .adm-form-item-label {
    margin-bottom: 8px !important;
    font-weight: bold;
    font-size: 1.1em;
    color: $brand-color;
  }

  .adm-selector-item {
    padding: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 290px!important;
  }
}

.price_range {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .adm-input {
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-left: 3%;

    .adm-input-element {
      padding: 1% 6%;
    }
  }
}
