.all_value_order{
  margin-top: 1%;
  border-radius: 10px;
  padding: 3% 1%;

  .adm-list-default{
    border-radius: 8px!important;
  }

  .adm-list-default .adm-list-body{
    border-radius: 8px!important;
  }
}
