@import "src/global/global.scss";

.account-bar{
  text-align: center!important;
  align-items: center!important;
  background-color: #ED6724;
  padding: 2%;

  &__icon-size{
    color: white;
    span{
      margin-top: 5px;
    }
  }

  &__search-bar{
    padding: 2%;
  }
}

.services-shop{
  .adm-divider{
    margin: 8px 0;
  }
  &:last-child{
    .adm-divider{
      display: none;
    }
  }

  &__details{
    padding: 2%;
    display: flex;
    align-items: center!important;
  }

  &__title{
    font-size: 1.2em;
    font-weight: bold;
    color: $brand-color-light;
  }

}
