@import "src/global/global.scss";

.my_order_product{
  margin-top: 5%;
}

.orders {
  background-image: linear-gradient(90deg, rgba(246,231,224,1) 0%, rgba(234,229,226,1) 50%, rgba(223,228,227,1) 92%, rgba(233,219,223,0.9780287114845938) 100%);
  .adm-tabs-header-mask-right, .adm-tabs-header-mask-left{
    background: none;
  }
  .order_tab {
    .submit_delivery_request{
      margin-top: 3.3%;
    }

    .adm-tabs-content{
      padding: 2px;
    }
    .adm-tabs-tab-active::after {
      background-color: $brand-color;
    }

    .adm-tabs-tab-active {
      color: $brand-color;
    }

    .all-orders {
      margin-top: 3%;
      border-radius: 10px;
      background-color: white;
      padding: 1% 4%;

      .adm-list-item-content-main{
        padding: 0 0 12px 0!important;
      }

      .adm-collapse-panel-header .adm-list-item-content-main{
        padding: 6px 0!important;
      }

      .adm-divider{
        //margin: 12px 0!important;
      }

      .adm-list-item{
        padding-left: 0!important;
      }

      .adm-list-item-content{
        padding: 0!important;
        border-bottom: none!important;
        border-top: none!important;
      }

      .adm-list{
        font-size: 12px!important;
      }

      .payment-status{
        text-align: right;
      }

      .order_footer{
        margin-top: 5%;
        align-items: center;

        &__button{
          button{
            border-radius: 25px!important;

            &:hover {
              color: $hover-color;
              border-color: $hover-color;
            }
          }
        }
      }

      .total_amount{
        display: flex;
        justify-content: flex-end;
        font-weight: bold;
        font-size: 14px;
        color: black!important;

        &__total{
          color: $brand-color;
        }
      }

      .order-product-details {
        align-items: center!important;

        .product_checkbox{
          .adm-checkbox .adm-checkbox-custom-icon{
            font-size: 18px;
          }

          .adm-checkbox .adm-checkbox-icon {
            width: 18px!important;
            height: 18px!important;
          }
        }
        .product_image {
          width: 100%;
          height: auto;
          border-radius: 8px;
          border: 1px solid #f0f0f0;
        }

        .product_meta{
          margin-top: 1%;
          .product_parcel{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: black;
            margin-top: 3%;

            &__title{
              margin-left: 10%;
              font-weight: bold;
            }
          }

          .product_price{
            font-weight: bold;
            color: $brand-color-light;

            .adm-button{
              padding: 0 5px!important;
              margin-left: 2%;
            }
          }

          .product_status{
            margin-top: 2%;
            color: black;
            &__pay_type{
              font-weight: bold;
              color: $brand-color-light;
            }
          }

          .product_attr{
            @extend %txt-overlap;
          }
        }

        .product_title {
          display: flex;
          align-items: center;
          &__title {
            span{
              color: $txt-color;
              @extend %txt-overlap;
              transition: $transition color;
              cursor: pointer;

              &:hover {
                color: $hover-color;
              }
            }
          }

          &__price, &__qty{
            text-align: right;
          }
        }
      }

      &__order-text {
        font-weight: bold;
        color: black;
        font-size: 0.97em;
        display: flex;
        align-items: center;
      }

      &__order-date {
        font-size: 0.8em;
        color: $txt-color;
      }

      .adm-space {
        --vertical-size: 4px !important;
      }
    }
  }
}

.product_details_order{
  cursor: pointer;
  margin-top: 4%;
  margin-bottom: 2%;

  //&:nth-last-child(2) {
  //  .adm-divider{
  //    display: none;
  //  }
  //}
}

.product_order_details{

  .pay-invoice{
    text-align: right!important;
  }

  &__order-text {
    font-weight: bold;
    color: black;
    font-size: 16px;
    display: flex;
    align-items: center;

    .order_status{
      margin-left: 6%;
    }
  }

  &__order-date{
    font-size: 14px;
  }

  .pay-invoice{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .adm-button{
      padding: 5px 15px!important;
    }
  }
}

.product_details_step{
  margin-top: 5%;
  margin-bottom: 5%;

  .product_details_button{
    color: $brand-color-light;
    padding: 0.3rem 0;
    --background-color: rgba(254,152,1,.12);
    --border-color:rgba(254,152,1,.12);
    margin-bottom: 3%;
  }

  &__title{
    font-size: 1.2em;
    color: $brand-color-light;
    font-weight: bold;
  }

  .view_more{
    margin-left: 0.6%;
    margin-top: 2%;
    span{
      font-size: 16px;
    }
  }

  .adm-step-icon-container{
    font-size: 6px!important;
  }

  .adm-steps-vertical .adm-step .adm-step-indicator{
    margin-top: 1% !important;
  }

  .adm-steps-vertical{
    padding: 0!important;
  }

  .step_des{
    display: flex;
    flex-direction: column;

    &__message{
      color: black;
      margin-top: 2%;
    }
  }
}

.product_all_details{
  padding: 4% 2% 0 2%;
  background-color: #F5F5F5;
  margin: 4% 2%;
  border-radius: 8px;
  .adm-divider{
    display: none;
  }

  .collapse_log_panel{
    margin-top: 2%;
    .adm-capsule-tabs-tab{
      background-color: white;
    }
    .adm-capsule-tabs-tab-active{
      color: #fff;
      background-color: var(--adm-color-primary);
    }
    .adm-list-default{
      border-radius: 8px!important;
    }
    .adm-list-item-content{
      padding: 8px 8px 8px 0!important;
    }
  }
}

.product_details_with_order {
  .product_image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
  }

  .product_meta{
    margin-top: 2%;

    .shipping_charges_order{
      font-size: 0.9em;
      color: #597ef7;
      font-weight: bold;
    }

    .product_meta_grid{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    .product_tracking{
      font-weight: bold;
      color: #722ed1;
    }

    .product_qty_price{
      color: $brand-color-light;
      font-weight: bold;
      font-size: 1.3em;
    }

    .product_price{
      font-weight: bold;
      color: $brand-color-light;

      .adm-button{
        padding: 0 5px!important;
        margin-left: 2%;
      }
    }

    .product_status{
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      color: black;
      align-items: center;
    }

    .product_attr{
      @extend %txt-overlap;
    }
  }

  .product_title {
    display: flex;
    align-items: center;
    &__title {
      span{
        color: $txt-color;
        @extend %txt-overlap;
        transition: $transition color;
        cursor: pointer;

        &:hover {
          color: $hover-color;
        }
      }
    }

    &__price, &__qty{
      text-align: right;
    }
  }
}

.transactions{
  &__title{
    font-size: 18px;
    font-weight: bold;
  }

  .trans_grid{
    margin-top: 2%;
    align-items: center;

    &__icon{
      font-size: 24px!important;
      color: $brand-color-light;
    }

    span{
      font-size: 1.1em;
    }
  }
}

.order_summery{
  //margin-top: 5%;
  &__title{
    font-size: 18px;
    font-weight: bold;
  }

  .order_details{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2%;

    &__total_value{
      color: $brand-color-light;
    }

    span{
      font-size: 15px!important;
    }
  }
}

.order_total_summery{
  display: flex;
  align-items: center;
  justify-content: space-between;

  span{
    font-size: 15px!important;
    color: $brand-color;
    font-weight: bold;
  }
}

.need_help{
  margin: 10% 0;
}

.order_deliverable_title{
  align-items: center;
  .order_checkbox {
    .adm-checkbox .adm-checkbox-custom-icon {
      font-size: 18px;
    }

    .adm-checkbox .adm-checkbox-icon {
      width: 18px!important;
      height: 18px!important;
    }
  }
}

.package_details{
  background-color: #F8F8F8;
  text-align: center;
  margin-bottom: 3%;
  margin-top: 3%;
  border-radius: 8px;
}

.order_bar{
  align-items: center;
}

.view_log_details{
  margin-top: 4%;

  &__title{
    font-weight: bold;
    font-size: 1.3em;
    color: #52c41a;
  }

}

.transactions-table{
  &:last-child{
    .adm-divider{
      display: none;
    }
  }

  .transactions-table-title{
    font-weight: bold;
    font-size: 1.1em;
  }

  .transactions-table-grid{
    text-align: right;
  }
}

.transactions-table-amount{
  font-weight: bold;
  font-size: 1.1em;
  color: $brand-color-light;
}

.tras_table{
  &__title{
    font-weight: bold;
  }

  .adm-list-item{
    font-size: 0.8em!important;
    padding-left: 0!important;
  }

  &:first-child{
    border-top: none!important;
  }
}

.issue_action{
  align-items: center!important;

  &__title{
    font-size: 1.2em;
    font-weight: bold;
    color: black;
  }

  &__sub_title{
    color: $txt-color;
  }

  &__sub_title_value{
    color: $brand-color-light;
    font-weight: bold;
  }
}

.issue_title{
  margin-top: 3%;
  margin-bottom: 4%;
  font-size: 1.1em;
  font-weight: bold;
}

.issue_from{
  padding: 3%;

  &__title{
    color: $txt-color;
    font-size: 0.9em;
  }
}

.submit_form{
  .adm-list-item-content{
    border-bottom: none!important;
  }
}

.order_details_status{
  padding-top: 0!important;
  padding-bottom: 0!important;
  font-size: 14px!important;
}

.issue_price_range{
  font-size: 1.1em!important;

  &__title{
    color: $brand-color-light;
    font-weight: bold;
  }
}
