@import "src/global/global.scss";
@import "src/global/abstracts/_mixins.scss";

.tab-bar-cart{
  height: 50px;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 999;
  border-top: 1px solid #efefef;

  @include min-media-width(767) {
    width: 500px!important;
    margin: 0 auto!important;
    left: 0!important;
    right: 0!important;
  }

  .adm-tab-bar-item{
    padding: 0!important;
    color: white!important;
  }

  .tab_bar_cart{
    flex: 0!important;
    margin-left: 5%;
    margin-right: 5%;
    color: black!important;
  }

  .tab_bar_favorite{
    flex: 0!important;
    margin-left: 2%;
    margin-right: 5%;
    color: var(--adm-color-primary) !important;
    .store {
      color: var(--adm-color-primary) !important;
    }
  }

  .tab_bar_button{
    justify-content: normal!important;
    display: inline-block!important;
    text-align: right!important;
    width: 100%;
    transform: translateX(.48rem);
    .adm-badge-wrap{
      display: block;
    }
    .adm-tab-bar-item-icon{
      font-size: 22px!important;
      margin: 0!important;
    }
  }
}

.add_to_cart{
  background-color: $brand-color-light;
  color: white;
  --border-radius: 0!important;
  --border-width: 0!important;
  //transform: skewX(-20deg);
}

.buy_now{
  background-color: #13c2c2;
  color: white;
  --border-radius: 0!important;
  --border-width: 0!important;
  //transform: skewX(-20deg);
}

.adm-button-large{
  padding: 15px 22px !important;
  font-size: 14px!important;
}

.adm-dialog-wrap{
  width: 90% !important;
}

.cart_text{
  font-size: 1.4em;
  color: $txt-color;
  text-align: center;
}

.cart_title{
  font-size: 1em;
  color: $brand-color-light;
  text-align: center;
}

.cart_button{
  background-color: #13c2c2;
  border: 1px solid #13c2c2;
}
