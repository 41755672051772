@import "src/global/global.scss";

.seller_rating{
  padding: 5% 1% 5% 1%;
  &__title{
    font-size: 1.2em;
    padding: 2%;
    font-weight: bold;
    //color: $brand-color;

    a{
      text-decoration: none;
    }
  }

  .seller_rating_details{
    margin-top: 5%;

    .seller_rating_log_details{
      font-size: 1.1em;
      &__tag{
        color: white!important;
      }
      &__rate{
        color: #28201B!important;
      }
      div{
        color: $txt-color;
        span{
          color: $brand-color-light;
          font-weight: bold;
          text-align: right;
        }
      }
    }
    .seller_original_rating{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5% 1%;
      border-right: 1px solid #E2E2E2;

      &__rating{
        font-size: 18px;
        font-weight: bold;
      }

      &__status{
        font-size: 16px;
        color: $txt-color;
        text-align: center;
        //@extend %txt-overlap;
      }
    }
  }
}

.store_button{
  margin-top: 3%;

  &__seller_middle{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button{
    font-weight: bold;
  }

  &__follow{
    color: $brand-color-light;
    border: 2px solid $brand-color-light;
  }
}
