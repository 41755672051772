@import "global/global.scss";

.wallet_button{
  .adm-button-small{
    font-size: 14px!important;
  }
}

.withdraw_request{
  font-weight: bold;
  font-size: 1.4em;

  &__balance{
    color: $brand-color;
  }
}

.withdraw_method{
  &__title{
    font-size: 1.4em;
    font-weight: bold;
  }

  &__sub_title{
    font-size: 1.1em;
  }
}

.transactions_table{
  overflow-x: auto;
  &__title{
    //font-weight: bold;
    font-size: 1.4em;
  }

  &__tasn{
    //font-weight: bold;
    font-size: 1.1em;
  }

  &__amount{
    //font-weight: bold;
    font-size: 1em;
  }

  table {
    min-width: 250px;
    border-collapse: collapse;
    //width: 100%;
    margin-top: 4%;
    border: none!important;
  }

  thead, tbody {
    th, td {

      &:first-child {
        width: 41%;
      }
      &:nth-child(2) {
        width: 27%;
      }
    }
  }

  td, th {
    text-align: left;
    padding: 8px;
  }

  thead>tr:first-child{
    border-bottom: 1px solid #dddddd!important;
  }

}

.withdraw_table{
  overflow-x: auto;
  &__title{
    font-size: 1.4em;
  }
  &__tasn{
    font-size: 1.1em;
  }
  &__amount{
    font-size: 1em;
  }

  table {
    min-width: 250px;
    border-collapse: collapse;
    //width: 100%;
    margin-top: 4%;
    border: none!important;
  }

  thead, tbody {
    th, td {

      &:first-child {
        width: 40%;
      }
      &:nth-child(2) {
        width: 10%;
      }
    }
  }

  td, th {
    text-align: left;
    padding: 8px;
  }

  thead>tr:first-child{
    border-bottom: 1px solid #dddddd!important;
  }

}

.windrow_form{
  margin-top: 2%;
  height: 55vh;
  overflow-y: scroll;

  .adm-form-item-label{
    margin-bottom: 10px!important;
  }

  .adm-list-item-content{
    border-bottom: none!important;
    border-top: none!important;
  }

  .adm-input{
    --font-size : 16px;
    border: 1px solid #E9E8E7;
    border-radius: 8px;

    .adm-input-element{
      padding: 2% !important;
    }
  }
}

.format_form{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.with_history{
  font-size: 1.1em;
  font-weight: bold;
  color: $brand-color-light;
}

.with_history_type{
  font-size: 0.8em;
}
.with_history_number{
  font-size: 0.8em;
}

.wallet_capsule{
  .adm-capsule-tabs-content{
    padding: 6px!important;
  }
}

