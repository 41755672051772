@import "src/global/global.scss";
.product_des {

  .stock, .sold{
    color: $txt-color !important;
    font-size: 1.2em;
  }

  .stock_amount, .sold_amount{
    font-size: 1.2em;
    color: $brand-color;
  }

  .product_des_grid{
    align-items: center;
  }

  &__title {
    font: 1.3em PingFang SC, microsoft yahei, STHeitiSC-Light, simsun, sans-serif;
    color: black;
    @extend %txt-overlap;
    -webkit-line-clamp: 2!important;
  }

  &__notice_des{
    color: #52c41a!important;
    padding: 1% 0 2% 0;
    font: 13px/1.5 PingFang SC, microsoft yahei, STHeitiSC-Light, simsun, sans-serif;
  }

  .product_front_price {
    padding: 1% 0 2% 0;
    align-items: baseline;

    &__price {
      color: $txt-color !important;
      font-size: 1.425rem;
    }

    &__sign, &__amount {
      font-size: 1.325rem;
      color: $brand-color;
      font-weight: bold;
    }

    &__delete_price__amount{
      font-size: 0.9rem;
      color: $txt-color!important;
      font-weight: bold;
      margin-top: 4%;
    }
  }

  .product_linking {
    padding: 1% 0;

    &__title_link{
      text-align: right;

      &__link{
        font-size: 0.888rem !important;
        a{
          text-decoration: none;
        }
      }
    }

    &__text {
      font-size: 0.888rem !important;
    }

    &__exist {
      font-size: 1.400em;

      button {
        @extend %button;
        background-color: #FF3D00;
        width: 1.5625rem;
        height: 1.5625rem;
        line-height: 1.5625rem;
        font-size: 0.875rem;
        text-align: center;
        border-radius: 50%;
        color: #fff;

        &.active, &:hover {
          background-color: #FF3D00;
          color: #fff;
        }
      }
    }

    &__wish_list {
      font-size: 1.400em;

      button {
        @extend %button;
        background-color: #fff;
        width: 1.7625rem;
        height: 1.7625rem;
        line-height: 1.7625rem;
        font-size: 0.875rem;
        text-align: center;
        border-radius: 50%;
        color: $txt-color;
        border: 1px solid #d9d9d9;

        &.active, &:hover {
          background-color: #FF3D00;
          color: #fff;
        }
      }
    }
  }
}

.ws_qty_range {

  &__box {
    text-align: center;
    padding: 8px;
    border-radius: 4px;
    width: 30%;
    position: relative;
    background: #f1f1f1;
  }
  &__bg_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $brand-color;
    opacity: 0.1;
  }
  &__price {
    color: $brand-color;
  }
}
