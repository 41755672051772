@import "src/global/global.scss";

.address_modal{
  padding: 4%;
  display: flex;
  flex-direction: column;

  .adm-selector-item{
    padding: 4px!important;
  }

  .address_tab{
    padding: 4%;
    background-color: #FBFBFB;
    border-radius: 5%;

    &__name, &__address, &__mobile{
      font-size: 15px;
    }
  }

  .delivery_button{
    margin-top: 10%;
  }

  .delivery_info{
    margin-top: 5%;
    display: flex;
    flex-direction: column;

    &__title{
      margin-top: 2%;
      font-size: 15px!important;
    }
  }

  .courier{
    margin-top: 5%;

    .adm-space{
      margin-top: 5% !important;
    }

    .adm-checkbox-icon{
      width: 16px!important;
      height: 16px!important;
    }
  }

  &__title{
    font-size: 18px;
    color: black;
    margin-bottom: 3%;

    &__value{
      color: $brand-color !important;
      font-size: 1em!important;
    }
  }
}
