@import "src/global/global.scss";
.share_grid{
  align-items: center;
  justify-content: center;
  margin-top: 5%;

  &__title{
    font-size: 1.3em;
    font-weight: bold;
    color: $brand-color;
  }

  .share_image{
    background-color: $brand-color;
    border-radius: 50%;
    img{
      padding: 21% 19%;
      width: 60%;
      height: 60%;
    }
  }
}

.product_details_header{
  // background-image: linear-gradient(0deg, rgba(246,145,29,1) 0%, rgba(242,101,30,1) 100%);
  position: sticky;
  top: 0;
  z-index: 999;

  .adm-nav-bar-title{
    padding: 0!important;
  }

  .adm-nav-bar-back{
    margin-right: 4px!important;
  }

  .adm-nav-bar{
    --height : 60px;
    color: white;
    font-weight: bold;
  }
}

.left_nav{
  .adm-search-bar{
    width: calc(100% - 60px);

    @media only screen and (min-width: 300px) {
      transition: .5s; // animate when window resizing
      width: calc(100% - 95px);
    }

    @media only screen and (min-width: 330px) {
      transition: .5s; // animate when window resizing
      width: calc(100% - 55px);
    }

    @media only screen and (min-width: 370px) {
      transition: .5s; // animate when window resizing
      width: calc(100% - 25px);
    }

    @media only screen and (min-width: 400px) {
      transition: .5s; // animate when window resizing
      width: calc(100% - 10px);
    }

    @media only screen and (min-width: 480px) {
      transition: .5s; // animate when window resizing
      width: calc(100% - 10px);
    }
  }
}
