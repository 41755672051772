@import "src/global/global.scss";

.request_product{
  margin-top: 3%;
  min-height: 40vh;
}

.request_tab{
  margin-bottom: 5%;
  .adm-tabs-header{
    border-bottom: none!important;
  }
  .details_tab{
    &__title{
      color: $txt-color;
      @extend %txt-overlap;
      transition: $transition color;
      cursor: pointer;
      font-size: 1.1em;
      -webkit-line-clamp: 2;

      &:hover {
        color: $hover-color;
      }
    }

    .shipping_charges{
      margin-top: 1%;
      font-size: 0.9em;
      color: $txt-color;
      font-weight: bold;
    }
  }

  .button_bottom{
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
  }
}

.product_request{
  padding: 6% 4%;
  background-color: white;
  margin-bottom: 5%;

  .adm-list-item-content-main{
    padding: 8px 0!important;
  }

  .product_request_meta{

    .image_data{
      width: 120px;
      max-height: 120px;
      &__image{
        width: 100%;
      }
    }

    .adm-input, .adm-text-area{
      --font-size : 16px;
      border: 2px solid #E9E8E7;
      border-radius: 8px;

      .adm-text-area-element{
        padding: 3% !important;
      }

      .adm-input-element{
        padding: 2% 4% !important;
      }
    }

    .adm-list-item-content{
      border-bottom: none!important;
      border-top: none!important;
    }
  }

  //.adm-list-item, .adm-form .adm-form-footer{
  //  padding-left: 2px!important;
  //}

  .product_request_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $brand-color;

    &__title{
      font-weight: bold;
      font-size: 16px;
    }

    &__icons{
      font-size: 18px;
    }
  }

  .product_request_form{
    margin-top: 3%;
  }
}

.form-inline{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button_remove{
    margin-top: 6%;
  }
}

.add_button{
  margin-top: 3%;
}

.request_tab_value{
  background-color: #f5f5f5;
  padding: 4%;
  border-radius: 8px;
  &:last-child{
    .adm-divider{
      display: none;
    }
  }
}

.request_price{
  font-weight: bold;
  font-size: 1.3em;
  color: $brand-color;
}

.image-search {

  &__display{
    display: none;
  }
  input {
    padding:0;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -99;
  }
  button {
    padding: 0;
    line-height: 20px;
    border: none;
    background: none;
  }
}

.adm-image-uploader-upload-button-wrap{
  padding: 0 5%;
  position: relative;
}

.adm-image-uploader-upload-button-wrap .adm-image-uploader-upload-button {
  background-color: #f5f5f5;
  text-align: center;
  line-height: 10px;
  display: block;
}

.adm-image-uploader-cell {
  position: relative;
  width: 100% !important;
  height: 60px!important;
  border-radius: 4px;
  overflow: hidden;
}

.adm-image-uploader-upload-button-wrap .adm-image-uploader-upload-button-icon {
  color: #999;
  font-size: 60px;
}

.price_qty_request{
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__stepper {
    width: 100px;
  }

  &__note {
    text-decoration: underline;
  }
}

.request_image_product{
  display: flex;
  background-color: #EEEEEE;
  padding: 0;
  transition: 300ms ease-in-out box-shadow;
  cursor: pointer;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  max-width: 6em;
  height: 80px;
  align-items: center;
  justify-content: center;

  &__thumb{
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    &__image {
      max-width: 240px;
      max-height: 240px;
      overflow: hidden;
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5%;
    }
  }
}
