.collapse_value{
  width: 100%;
  .adm-list-item{
    padding-left: 0!important;
  }

  .collapse_panel{
    .adm-list-item-content{
      font-size: 0.8em;
      color: black;
      font-weight: bold;
      align-items: center!important;
      justify-content: space-between!important;
    }
  }

  .adm-list-item-content{
    padding: 12px 6px 18px 6px !important
  }

  &__grid{
    align-items: center;

    &__title{
      margin-top: 8%;
      font-size: 0.9em;
      color: black;
    }
    &__item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.tree_select{

  .adm-tree-select{

    div:first-child{
      .adm-tree-select-item{
        padding: 6px 12px!important;
        align-items: center!important;
        justify-content: center!important;
      }
    }

    div:last-child{
      .adm-tree-select-item{
        padding: 6px 8px 0 4px!important;
      }
    }

  }
  .adm-tree-select-item{
    padding: 6px 6px 0 12px!important;
  }
}

.mother_categories{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  &__icons{
    font-size: 24px!important;
  }
}
