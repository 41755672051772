.search_store{
  padding: 0 1% 5% 1%;
  a{
    text-decoration: none;
  }
  .adm-divider{
    margin: 8px 0!important;
  }

  .store_lists{
    padding: 2% 1% 4% 1%;
    .store_lists_grid{
      --gap-vertical: 20px!important;
    }

    .store_lists_grid_item{
      align-items: center!important;
      &__store{
        text-align: center!important;
      }
    }
  }

  .search_store_grid{
    &__text{
      color: #82857a;
      font-size: 1.133em;
    }
  }
}
