.disclaimer{
  padding: 3% 4%;

  .disclaimer_title{
    display: flex;
    justify-content: space-between;
  }

  &__text{
    font-size: 18px!important;
  }

  &__icons{
    font-size: 18px!important;
  }

  &__content{
    margin-top: 2%;
  }

}
