@import "src/global/global.scss";

.product_price {
  &__original {
    font-weight: bold;
    color: $brand-color;
    font-size: 1.2em;
  }

  &__discount {
    font-weight: bold;
    color: $brand-color;
    font-size: 1.2em;
  }
  &__del {
    font-weight: bold;
    font-size: 1em;
    color: #5D6154 !important;
    margin-left: 3px;
  }
}
.text-lg {
  font-size: 1.8em;
  &__del {
    font-size: 1.5em;
  }
}
