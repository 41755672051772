.campaign_timer_grid{
  color: white;
  align-items: center!important;

  .counter_grid{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-end;

    &__end{
      margin-right: 5px;
    }

    &__counter{
      display: flex;
      flex-direction: row;
      padding: 3% 0;
      &__title{
        color: #F5F5F5;
        font-size: 1.1em;
        font-weight: bold;

        .dot{
          font-size: 1em;
          margin-right: 3px;
        }
      }
    }
  }

  .campaign_count_down_format{
    //background-color: #120106;
    //border: 2px solid #2A2627;
    padding: 1%;
    //border-radius: 4px;
    //--gap: 0!important;

    &__sub_title{
      color: #F5F5F5;
      font-size: 0.9em;
    }
  }


}
