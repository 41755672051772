.skeleton_image{
  --width: 100%;
  --height: 100px;
  --border-radius: 8px;
}

.skeleton_image_icon{
  --width: 60px;
  --height: 60px;
  --border-radius: 50%;
}

.skeleton_product_image{
  --width: 100px;
  --height: 100px;
  --border-radius: 8px;
}
