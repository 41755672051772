@import "global/global.scss";

.issue_list{
  --gap: 4px!important;
  &__title{
    font-size: 0.97rem;
    font-weight: bold;
  }

  &__sub_title{
    font-size: 0.9rem;
    font-weight: 100;

    &__price{
      font-size: 1.2rem;
      font-weight: bold;
      color: $brand-color-light;
    }
  }

  &__price{
    color: $brand-color-light;
  }
}
