@import "src/global/abstracts/_mixins.scss";
@import "src/global/global.scss";

.product_manual_search{
  // background: linear-gradient(0deg, rgba(246,145,29,1) 0%, rgba(242,101,30,1) 100%);
  background-color: $brand-color;
  padding: 4% 3%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 999;

  @include min-media-width(767) {
    width: 500px!important;
    margin: 0 auto!important;
    left: 0!important;
    right: 0!important;
    padding: 2%;
  }

  .image-search {

    //.sr-only{
    //  border: 0;
    //  clip: rect(0, 0, 0, 0);
    //  height: 1px;
    //  margin: -1px;
    //  overflow: hidden;
    //  padding: 0;
    //  position: absolute;
    //  width: 1px;
    //}

    &__display{
      display: none;
    }
    input {
      padding:0;
      width: 0;
      height: 0;
      position: absolute;
      z-index: -99;
    }
    button {
      padding: 0;
      line-height: 20px;
      border: none;
      background: none;
    }
  }

  .adm-search-active{
    --adm-color-primary: white!important;
  }

  .product_search_grid{
    align-items: center!important;

    &__icons{
      font-size: 24px!important;
      color: white;
    }
  }
}


.search_popup_header{
  padding: 3%;
  text-align: center;
  color: $txt-color;

  &__icon{
    font-size: 18px;
    text-align: center;
  }

  &__text{
    font-size: 1em;
    font-weight: bold;
  }
}

.search_popup_button{
  border-radius: 25px;
  text-align: center;
  border-color: $bgc-button;
}
